export const fr = {
  'carrierLookupPage': {
    carriers: 'Transporteurs',
    fullName: 'Nom et prénom',
    search: 'Rechercher un transporteur ou un SCAC',
    synonyms: 'Synonymes',
  },
  'common': {
    showJson: 'Afficher Json',
    carrierTime: 'Temps de transport',
    dashboard: 'Tableau de bord',
    requestReceived: 'Demande reçue',
    viewTransitTimeDashboard: 'Afficher l\'historique du temps de transit',
    requestReceivedMessage: 'Merci d\'avoir pris contact avec nous. Notre équipe vous contactera dans les prochaines 48 heures.',
    noThanks: 'Non merci',
    contactUs: 'Contactez-nous',
    contactUsToUnlock: 'Contactez-nous pour débloquer',
    dataUnavailable: 'Données non disponibles',
    co2Emission: 'Émission de CO2e',
    emission: 'Émission',
    locked: 'Fermé à clé',
    predictionLocked: 'Prédiction verrouillée',
    preferences: 'Préférences',
    enableNewUI: 'Activer la nouvelle interface utilisateur',
    iAccept: 'J\'accepte le',
    termsOfUse: 'Conditions d\'utilisation',
    openInNewWindow: 'S\'ouvre dans une nouvelle fenêtre',
    unavailable: 'Indisponible',
    availableSoon: 'Bientôt disponible',
    send: 'Envoyer',
    portCongestion: 'La congestion portuaire',
    developerTooling: 'Outils de développement',
    activeOrg: 'Organisation active',
    actual_arrival: 'Arrivée réelle',
    added_on: 'Ajouté le',
    anchorageStay: 'Séjour au mouillage',
    apiDocumentation: 'Documentation API',
    archive: 'Archive',
    archived: 'Archivé',
    arrival_status: 'Statut d\'arrivée',
    arrive_at_pod: 'Arrivée au POD',
    arrive_at_pot: 'Arriver au POT',
    arrived: 'Arrivé',
    arriving: 'En arrivant',
    ts_waiting_time_in_days: 'Temps d\'attente TS (jours)',
    is_transhipment: 'En arrivant',
    bls: 'bls',
    booking_no_: 'Numéro de réservation.',
    byCarrier: 'par transporteur',
    cancel: 'Annuler',
    carriers: 'Transporteurs',
    confirm: 'Confirmer',
    confirmMessage: 'Êtes-vous sur de vouloir continuer?',
    consignee: 'Destinataire',
    container: 'Récipient',
    containerNotMappedWithBL: 'Conteneur non mappé avec BL',
    containerNotTrackedByCarrier: 'Conteneur non suivi par le transporteur',
    containers: 'Conteneurs',
    contents: 'Contenu',
    copied: 'Copié',
    copy: 'Copie',
    count: 'compter',
    days: 'Journées',
    delay_in_days: 'Délai en jours',
    delete: 'Effacer',
    depart_at_pol: 'Départ à POL',
    depart_at_pot: 'Départ au POT',
    direct: 'direct',
    discharge: 'Décharge',
    discharged: 'Déchargé',
    done: 'Fait',
    download: 'Télécharger',
    downloadAll: 'Tout télécharger',
    early: 'Tôt',
    earlyJourney: 'Premier voyage',
    edit: 'Éditer',
    email: 'E-mail',
    emptyReturn: 'Retour vide',
    error: 'Erreur',
    errors: 'les erreurs',
    events: 'Événements',
    exceptions: 'Exceptions',
    from: 'de',
    gateOut: 'Porte de sortie',
    gatein_at_pol: 'Porte d\'entrée à POL',
    gateout_at_pod: 'Porte de sortie au POD',
    gateout_at_pol: 'Porte à POL',
    importer: 'Importateur',
    inThis: 'dans ce',
    incidentList: {
      anchorageStay: 'Séjour au mouillage',
      nonOptimalRoute: 'Itinéraire non optimal',
      outdatedSchedule: 'Calendrier obsolète',
      portStay: 'Séjour au port',
    },
    helpCenter: 'Centre d\'aide',
    incidents: 'incidents',
    init: 'Init',
    internal_ref_no_: 'Réf. Non.',
    late: 'En retard',
    load_at_pol: 'Charger à POL',
    load_at_pot: 'Charger au POT',
    loading: 'Chargement ...',
    logout: 'Se déconnecter',
    logs: 'Journaux',
    lookup: 'Chercher',
    new: 'Nouveau',
    noDataFromCarrier: 'Aucune donnée du transporteur',
    nonOptimalRoute: 'Itinéraire non optimal',
    of: 'de',
    okay: 'D\'accord',
    onTime: 'À temps',
    others: 'Les autres',
    or: 'OU',
    organization: 'Organisme',
    outdatedSchedule: 'Calendrier obsolète',
    p_o_no_: 'P.O. Non.',
    password: 'Mot de passe',
    pod: 'POD',
    pol: 'POL',
    pol_waiting_time_in_days: 'Temps d\'attente POL en jours',
    portNotInSchedule: 'Port non programmé',
    portStay: 'Séjour au port',
    ports: 'Ports',
    predicted_arrival: 'Arrivée prévue',
    proceed: 'Procéder',
    refreshDataConfirmMessage: 'Voulez-vous vraiment actualiser {total} données de conteneurs ?',
    reindexDataConfirmMessage: 'Voulez-vous vraiment réindexer les données ?',
    releaseNotes: 'Notes de version',
    return_at_pod: 'Retour au POD',
    save: 'sauvegarder',
    searchOrgs: 'Rechercher des organisations',
    selected: 'Choisi',
    showAll: 'Afficher tout',
    status: 'STATUT',
    statusList: {
      'Arrived': 'Arrivé',
      'NO_DATA_RECEIVED_FROM_CARRIER': 'Aucune donnée du transporteur',
      'BL_NOT_FOUND': 'BL introuvable',
      'BULK_INIT': 'Préparation des prédictions',
      'CONTAINER_IDLE_AFTER_GATEOUT': 'Conteneur inactif après la sortie de la porte',
      'CONTAINER_NOT_FOUND': 'Conteneur introuvable',
      'CONTAINER_NOT_MAPPED_WITH_BL': 'Conteneur non mappé avec BL',
      'CONTAINER_RETURNED_TO_CARRIER': 'Conteneur retourné au transporteur',
      'INIT': 'Préparation des prédictions',
      'NO_DATA_FROM_PROVIDER': 'Aucune donnée du transporteur',
      'On Time': 'À temps',
      'Preparing Predictions': 'Préparation des prédictions',
      'SUCCESS': 'Succès',
      'SUCCESS_EARLY_JOURNEY': 'Premier voyage',
      'EARLY_JOURNEY': 'Premier voyage',
      'TARGET_PORT_NOT_IN_ROTATION': 'Port non programmé',
      'earlyByXDays': 'Arrivée en avance de {days} jours',
      'lateByXDays': 'Arrivée en retard de {days} jours',
      'delayByDays': 'Retard de {jours} jour(s)',
    },
    status_code: 'Code d\'état',
    stop: 'arrêt',
    stops: 's\'arrête',
    supplier: 'Le fournisseur',
    switchActiveOrg: 'Changer d\'organisation active',
    switchTab: 'maintenant s\'il vous plaît passer à un autre onglet',
    t_m_s_ref_no_: 'TMS Réf. Non.',
    t_s: 'T/S',
    thereAreNo: 'Il n\'y a pas',
    unique: 'Unique',
    unknownError: 'Erreur inconnue',
    unload_at_pod: 'Décharger au POD',
    unload_at_pot: 'Décharger au POT',
    updatedSuccessfully: 'Mis à jour avec succés',
    updated_on: 'Mis à jour le',
    uploadLogs: 'Charger les journaux',
    vessel: 'Navire',
    vesselTracking: 'Suivi des navires',
    vessels: 'Navires',
    viewProfile: 'Voir le profil',
    makeAsDefault: 'Faire par défaut',
    containerNumber: 'Numéro de conteneur',
    billNumber: 'Numéro du connaissement',
    organisation: 'Organisation',
    addedUpdated: 'Ajouté et mis à jour',
    exceptionsIncidents: 'Exceptions et incidents',
    poNumber: 'Numéro de bon de commande',
    tmsRefNumber: 'Numéro de référence TMS',
    content: 'Contenu',
    tableView: 'Vue de tableau',
    calenderView: 'Affichage du calendrier',
    mapView: 'Vue de la carte',
    customLate: 'Retard personnalisé',
    viewName: 'Afficher le nom',
    viewDescription: 'Afficher la description',
    optional: 'Facultatif',
    setAsDefaultView: ' Définir comme vue par défaut.',
    portOfLoading: 'Port de chargement',
    transhipment: 'Transbordement',
    transhipments: 'Transbordement(s)',
    portOfDeparture: 'Port de déchargement',
    search: 'Recherche...',
    routeChange: 'Changement d\'itinéraire',
    anchorageDelay: 'Délai d\'ancrage',
    portSequenceChange: 'Changement de séquence de ports',
    carrierConsideringDelay: 'Transporteur envisageant un retard',
    weatherAdversity: 'Adversité météorologique',
    delayAtUpcomingPort: 'Retard au prochain port',
    carrierDataMissing: 'Données de transporteur manquantes',
    invalidInput: 'entrée invalide',
    remove: 'Retirer',
    filters: 'Filtres',
    loadingPleaseWait: 'Chargement, veuillez patienter',
    original: 'Original',
    portcast: 'Portcast',
    clearAll: 'Tout effacer',
    active: 'Actif',
    bookmarked: 'Favoris',
    bookmark: 'Signet',
    filterAllMessage: 'Tous : tous vos conteneurs, y compris les conteneurs actifs, archivés et avec erreur.',
    filterActiveMessage: 'Actif : conteneurs activement suivis. Cette liste exclut les conteneurs avec erreur ou archivés.',
    filterNewMessage: 'Nouveau : conteneurs importés au cours des dernières 24 heures.',
    filterLateMessage: 'En retard : tous les conteneurs actifs qui arrivent en retard.',
    filterArrivedMessage: 'Arrivé : les conteneurs suivants sont arrivés au POD.',
    filterBookmarkedMessage: 'Mis en signet : les conteneurs suivants ont été mis en signet par l\'un des utilisateurs de cette organisation.',
    selectAllContainers: 'Sélectionnez tous les conteneurs {count}',
    scheduleAlertMessage: 'Voulez-vous programmer des alertes pour ces conteneurs ? Recevez des notifications sur les étapes clés du conteneur livrées à votre adresse e-mail ou votre numéro de mobile',
    scheduleAlerts: 'Planifier des alertes',
    dontSchedule: 'Ne planifiez pas',
    bookmarkContainerMessage: 'Voulez-vous ajouter ces conteneurs à vos favoris ? Cela vous aidera à prioriser les conteneurs qui sont importants.',
    bookmarkContainers: 'Conteneurs de signets',
    dontBookmark: 'Ne pas mettre en signet',
    downloadContainerMessage: 'Voulez-vous télécharger le(s) conteneur(s) ?',
    downloadVesselMessage: 'Voulez-vous télécharger le(s) navire(s) ?',
    download300ContainerMessage: 'Voulez-vous télécharger le(s) conteneur(s) sélectionné(s) ? La sélection de plus de 300 conteneurs peut prendre plus de temps à traiter.',
    download300VesselMessage: 'Voulez-vous télécharger le(s) navire(s) sélectionné(s) ? La sélection de plus de 300 navires peut prendre plus de temps à traiter.',
    dontDownload: 'Annuler',
    shareViaEmail: 'Partager par e-mail',
    downloadNow: 'Télécharger maintenant',
    archiveContainerMessage: 'Voulez-vous archiver le(s) conteneur(s) ? L\'archivage arrêtera le suivi pour le(s) conteneur(s) sélectionné(s).',
    archiveContainer: 'Conteneur d\'archives',
    dontArchive: 'Ne pas archiver',
    deleteContainerMessage: 'La suppression d\'un conteneur est définitive. Il sera supprimé de votre liste.',
    deleteVesselsMessage: 'La suppression d\'un navire est définitive. Il sera supprimé de votre liste.',
    deleteContainer: 'Supprimer le conteneur',
    deleteVessel: 'Supprimer le navire',
    dontDelete: 'Ne supprimez pas',
    eta: 'ETA',
    carrierVessel: 'TRANSPORTEUR/BATEAU',
    action: 'ACTION',
    searchByContainer: 'Rechercher par conteneur, B/L ou numéro de bon de commande',
    searchByPodPolTs: 'Recherche par POL, POD ou T/S',
    searchByVesselCarrier: 'Recherche par navire ou transporteur',
    searchByCustomer: 'Recherche par client',
    searchByVessel: 'Recherche par numéro de navire ou numéro de B/L',
    applyGroupBy: 'Appliquer Grouper par',
    applySort: 'Appliquer Trier',
    showContainers: 'Afficher les conteneurs',
    switchOrganisation: 'Changer d\'organisation',
    showCountContainers: 'Afficher {count} conteneurs',
    showCountVessels: 'Afficher {count} navires',
    applyFilters: 'Appliquer le filtre',
    saveView: 'Enregistrer la vue',
    savedViews: 'Vues enregistrées',
    selectView: 'Sélectionnez Afficher',
    addNew: 'Ajouter nouveau',
    chooseView: 'Choisissez Afficher',
    applyView: 'Appliquer la vue',
    asDefault: 'par défaut',
    set: 'Ensemble',
    viewNameError: 'Le nom de la vue existe déjà',
    lateByDays: 'En retard de {start} à {end} jour(s)',
    lateByDaysNumber: 'En retard de {count}+ jour(s)',
    latestEvents: 'Derniers évènements',
    masterOrg: 'Organisation principale',
    emptyGateOutAtPOL: 'Portail vide (au POL)',
    gateInFullAtPOL: 'Porte en entier (au POL)',
    loadedAtPOL: 'Chargé (au POL)',
    departedFromPOL: 'Départ (de POL)',
    arrivalAtPOD: 'Arrivée (au POD)',
    dischargedAtPOD: 'Libéré (au POD)',
    gateOutFullAtPOD: 'Gate Out Full (au POD)',
    emptyReturnAtPOD: 'Retour vide (au POD)',
    lateStatus: 'Statut tardif',
    created: 'Créé',
    updated: 'Mis à jour',
    aisSatellite: 'Données satellitaires AIS',
    containerNotFound: 'Conteneur(s) introuvable(s).<br> Essayez de supprimer certains filtres.',
    vesselsNotFound: 'Navire(s) introuvable(s).<br> Essayez de supprimer certains filtres.',
    comingSoon: 'À venir',
    pleaseTryAgain: 'Veuillez vérifier à nouveau plus tard et signaler pour une enquête plus approfondie',
    port: 'Port',
    country: 'Pays',
    pol_country: 'Pays Pol',
    pod_country: 'Pays des gousses',
    clickToCopy: 'Cliquez pour copier',
    clickToCopyContainer: 'Cliquez pour copier le numéro de conteneur',
    generatingPrediction: 'Génération de prédiction',
    latest: 'Dernier',
    dontHaveAnAccount: 'Vous n\'avez pas encore de compte?',
    alreadyHaveAnAccount: 'Vous avez déjà un compte?',
    corporateEmailInvalidError: 'Veuillez utiliser l\'e-mail de l\'entreprise pour vous inscrire.',
    noOrgDialogTitle: 'Contacter l\'administration',
    noOrgDialogDescription: 'Vous n\'avez pas d\'organisation assignée, veuillez contacter l\'administration.',
    thankYou: 'Merci',
    needsAttention: 'Besoin d\'attention',
    onTheWay: 'en chemin',
    targetPort: 'Port cible',
    targetPortEta: 'Eta du port cible',
    PredictedActual: 'Prévu * / Réel',
    scheduledArrival: 'arrivée prévue',
    errorCode: 'Code d\'erreur',
    flaggedOn: 'Marqué sur',
    showVessels: 'Afficher les navires',
    selectAllVessels: 'Sélectionnez tous les navires {count}',
    restart: 'Redémarrage',
    reIndexContainers: 'Réindexer les conteneurs',
    reIndexMessage: 'Voulez-vous vraiment réindexer les données des conteneurs ?',
    refresh: 'Rafraîchir',
    refreshVessels: 'Rafraîchir les navires',
    refreshVesselsMessage: 'Voulez-vous vraiment actualiser les données de {count} navires ?',
    downloadSuccess: 'Conteneurs téléchargés avec succès.',
    somethingWentWrong: 'Quelque chose s\'est mal passé. Veuillez réessayer plus tard.',
    uploadingLimitExceed: 'Votre quota de téléchargement a été dépassé',
    containerUploadedSuccessfully: 'Conteneurs téléchargés avec succès',
    reportType: 'Type de rapport',
    timeRange: 'Intervalle de temps',
    selectFields: 'Sélectionnez les champs',
    departingContainers: 'Conteneurs au départ',
    departedContainers: 'Conteneurs partis',
    arrivingContainers: 'Conteneurs arrivant',
    arrivedContainers: 'Conteneurs arrivés',
  },
  'containerPage': {
    actual: 'Réel',
    actualArrivalAscending: 'Arrivée réelle - Croissant',
    actualArrivalDescending: 'Arrivée réelle - Décroissant',
    addContainer: 'Ajouter un ou plusieurs conteneurs',
    addContainers: 'Ajouter des conteneurs',
    addNewContainers: 'Ajouter de nouveaux conteneurs',
    addMoreContainers: 'AJOUTER PLUS DE CONTENANTS',
    addedOn: 'Ajouté le',
    addedOnMasterOrg: 'Organisation / Ajouté',
    addedOnSortByItems: {
      created_asc: 'Créé - Croissant',
      created_desc: 'Créé - Descendant',
    },
    allTab: 'Tout',
    archiveContainers: 'Conteneurs d\'archives',
    archivedTab: 'Archivé',
    arrival: 'Arrivée',
    arrivalSortByItems: {
      pod_actual_arrival_lt_asc: 'Arrivée réelle - Croissant',
      pod_actual_arrival_lt_desc: 'Arrivée réelle - Décroissant',
      pod_portcast_predicted_arrival_lt_asc: 'Arrivée prévue - Croissant',
      pod_portcast_predicted_arrival_lt_desc: 'Arrivée prévue - Descendante',
    },
    arrivalStatus: 'Statut d\'arrivée',
    arrivedAtPOD: 'Arrivé au POD',
    arrivedAtTS: 'Arrivé à T/S',
    arrivedTab: 'Arrivé',
    arrivingTab: 'En arrivant',
    billOfLading: 'Connaissement',
    bookingInfo: 'Informations de réservation.',
    bookingInformation: 'Informations de réservation',
    bookingNo: 'Numéro de réservation.',
    bookingNumber: 'Numéro de réservation',
    browseFiles: 'PARCOURIR LES FICHIERS',
    bulkUpload: 'Transfert groupé',
    carrier: 'Transporteur',
    carrierNo: 'Transporteur Non',
    carrierSCAC: 'Transporteur / SCAC',
    carrierSCACVessels: 'Transporteur SCAC / Navires',
    clear: 'Dégager',
    clearAppliedFilters: 'EFFACER LES FILTRES APPLIQUÉS',
    consignee: 'Destinataire',
    containerArchiveSuccessful: '{count} conteneurs archivés avec succès !!',
    containerEvent: 'Événement de conteneur',
    containerNo: 'Conteneur Non',
    containerNoBl: 'Conteneur No / BL',
    containerNotMappedWithBL: 'Conteneur non mappé avec BL',
    containerNotTrackedByCarrier: 'Conteneur non suivi par le transporteur',
    containerUploadErrorDuplicate: 'Tentative de téléchargement en double',
    containerUploadErrorInvalidContainer: 'Conteneur invalide Non',
    containerUploadErrorInvalidCarrier: 'Numéro de transporteur invalide',
    containerUploadErrorQuotaExceeded: 'Quota dépassé',
    containerUploadErrorMissingCarrier: 'Transporteur manquant',
    containerUploadErrorMissingContainer: 'Conteneur manquant Non',
    contentBrand: 'Contenu / Marque',
    contents: 'Contenu',
    contractualOriginalETA: 'Contractuel / ETA d\'origine',
    contractualOriginalETD: 'ETD contractuel / original',
    customer: 'Client',
    deletedAllInvalidText: 'vous avez supprimé tous les conteneurs invalides',
    departedFromPOL: 'Départ de POL',
    departedFromTS: 'Départ de T/S',
    departure: 'Départ',
    destination: 'Destination',
    discharge: 'Décharge',
    dischargedAtPOD: 'Libéré au POD',
    dischargedAtTS: 'Déchargé à T/S',
    disclaimer: 'basé sur la date initialement prévue par le transporteur',
    downloadAll: 'Télécharger tous les conteneurs',
    downloadContainers: 'Télécharger les conteneurs',
    downloadSample: 'Télécharger un exemple de CSV',
    downloadSelected: 'Télécharger les conteneurs sélectionnés',
    dropFilesHere: 'Déposez les fichiers ici',
    earlyJourney: 'Premier voyage',
    earlyTab: 'Tôt',
    emptyReturn: 'Retour vide',
    enterData: 'Entrez vos données ici',
    errorCodeFlaggedByTab: 'Code d\'erreur / Marqué par',
    exportErrorCSV: 'Exporter les erreurs au format CSV',
    fileSupport: 'Nous prenons en charge .CSV, .XLS et .XlSX',
    filterBookingNo: 'Filtrer par numéros de réservation',
    filterByBLs: 'Filtrer par BL',
    filterByCarriers: 'Filtrer par transporteurs',
    filterByContainerNos: 'Filtrer par numéro de conteneur',
    filterByPODPorts: 'Filtrer par ports POD',
    filterByPOLPorts: 'Filtrer par ports POL',
    filterByTSPorts: 'Filtrer par ports TS',
    filterByVessels: 'Filtrer par Navires',
    filterConsignee: 'Filtrer par destinataire',
    filterContents: 'Filtrer par contenu',
    filterImporter: 'Filtrer par importateur',
    filterInternalRef: 'Filtrer par numéros de référence internes',
    filterPoNo: 'Filtrer par numéros de bons de commande',
    filterSupplier: 'Filtrer par fournisseur',
    filterTmsRef: 'Filtrer par numéros de référence T.M.S',
    finalVesselName: 'Nom du dernier navire',
    firstVesselName: 'Nom du premier navire',
    flaggedOnTab: 'Marqué sur',
    gateInFull: 'Porte en entier',
    gateOutEmpty: 'Porte de sortie vide',
    gateOutFull: 'Gate Out complet',
    generateNewPredictions: {
      disabledTitle: 'La génération de prédictions est désactivée pour les conteneurs arrivés',
      subTitle: 'Nous vous fournirons de nouveaux ETA pour le conteneur',
      successMessage: 'Génération de nouvelles prédictions. Veuillez revenir après 1-2 minutes',
      title: 'Générer de nouvelles prédictions',
    },
    groupBy: 'Par groupe',
    groupingByUnique: 'Regroupement par Unique',
    importer: 'Importateur',
    initiallyScheduled: 'Initialement prévu',
    plannedArrival: 'Initialement prévu',
    planned: 'Initial',
    internalRef: 'Réf interne',
    internalRefNumber: 'Numéro de référence interne',
    invalidContainerNo: 'Numéro de conteneur invalide',
    journey: 'Périple',
    lateTab: 'En retard',
    latestEvent: 'dernier événement',
    latestPredicted: 'Dernier (prévu)',
    latestScheduled: 'Dernière programmation',
    loadedAtPOL: 'Chargé à POL',
    loadedAtTS: 'Chargé à T/S',
    loading: 'Chargement',
    mapColumns: 'Colonnes de carte',
    mapColumnsHeader: 'La ligne sélectionnée contient-elle des en-têtes de colonne ?',
    mapColumnsTip: 'Sélectionnez la colonne correspondante dans le fichier',
    miscellaneousInformation: 'Informations diverses',
    newTab: 'Nouveau',
    nextStepsInstruction: 'L\'étape suivante vous permettra de faire correspondre les colonnes de votre feuille de calcul aux bons points de données.',
    noDataFromCarrier: 'Aucune donnée du transporteur',
    noInvalidContainers: 'Il n\'y a pas de conteneurs non valides.',
    noSailingInfo: 'Aucune information de navigation',
    outboundHaulage: 'Transport sortant',
    outboundHaulageID: 'ID de transport sortant',
    packageQuantity: 'Quantité de paquet',
    poNo: 'N° de bon de commande',
    poNumber: 'Numéro de bon de commande',
    pod: 'COSSE',
    pol: 'POL',
    portNotinSchedule: 'Port non programmé',
    predicted: 'Prédit',
    predictedActualArrival: 'Arrivée prévue/réelle',
    predictedArrivalAscending: 'Arrivée prévue - Croissant',
    predictedArrivalDescending: 'Arrivée prévue - Descendante',
    preparingPredictions: 'Préparation des prédictions',
    productDescription: 'Description du produit',
    productDetails: 'détails du produit',
    productInfo: 'Information sur le produit.',
    productInformation: 'Information produit',
    redFlagComments: 'Entrez des commentaires ou tout autre élément que vous souhaitez ajouter',
    redFlagContainer: 'Conteneurs Drapeau Rouge',
    redFlaggedSuccessfulMessage: '{count} conteneurs ont été signalés avec succès en rouge. Vous pouvez également les trouver sous l\'onglet Red Flagged',
    redFlaggedTab: 'Drapeau rouge',
    refreshOrgData: 'Actualiser les données',
    reindexData: 'Réindexer les données',
    reviewContainers: 'Examiner les conteneurs',
    sailingDates: 'Dates de départ',
    sailingInfo: 'Informations sur la voile',
    sailingInfoNotAvailable: 'Informations de navigation non disponibles en raison d\'informations POL ou POD manquantes.',
    saleInfo: 'Informations sur la vente',
    salesOrder: 'Bon de commande',
    scheduled: 'Programmé',
    searchContainers: 'Conteneurs de recherche',
    selectAnotherRow: 'Sélectionnez une autre ligne',
    selectReasonForRedFlag: 'Sélectionnez les raisons du signalement',
    showEtaHistory: 'Afficher l\'historique ETA',
    sortBy: 'Trier par',
    sortByAddedOnDate: 'Trier par Ajouté le',
    sortByArrivalDate: 'Trier par dates d\'arrivée',
    source: 'La source',
    sourceFile: 'Fichier source',
    status: 'Statut',
    statusIncidents: 'Statut / Incidents',
    subtextAddContainers: 'Vous pouvez copier et coller plusieurs lignes à partir de votre fichier source ici.',
    success: 'Succès',
    supplier: 'Le fournisseur',
    supplierID: 'ID du fournisseur',
    title: 'Suivi des conteneurs',
    tmsRef: 'Réf TMS',
    tmsReferenceNumber: 'Numéro de référence TMS',
    ts: 'TS',
    unflagContainers: 'Ne pas signaler les conteneurs',
    updatedOn: 'Mis à jour le',
    uploadAddContainers: 'Télécharger/Ajouter des conteneurs',
    uploadCompleted: 'Téléchargement terminé',
    uploadFailed: 'Échec du téléchargement',
    uploadFailedMessage: 'Échec du chargement des conteneurs.',
    uploadMessage1: 'Vous avez téléchargé',
    uploadMessage2: 'conteneurs avec succès',
    vessel: 'Navire',
    vesselNotConfirmed: 'Navire non confirmé',
    vesselNotConfirmedTooltip: 'Le transporteur n\'a pas encore confirmé le nom du navire pour cette étape. Vérifiez à nouveau dans 48 heures',
    vesselVoyage: 'Navire, Voyage',
    viewRecentUploadLogs: 'Afficher les journaux de conteneur récemment téléchargés',
    search: 'Recherche',
    origin: 'Origine',
    selectPort: 'Sélectionnez Port',
    selectCountry: 'Choisissez le pays',
    addingContainerInProgress: 'Ajout du conteneur en cours...',
    AddCountContainers: 'Ajouter {count} conteneurs',
    new: 'Nouveau',
    createReport: 'Creer un rapport',
  },
  'containerUploadLogsPage': {
    all: 'Tout',
    billOfLadingNo: 'Connaissement non.',
    carrierScac: 'Transporteur SCAC',
    containerNo: 'Conteneur n°',
    duplicates: 'Doublons',
    error: 'Erreur',
    invalidContainers: 'Conteneurs invalides',
    invalidPayload: 'Charge utile invalide',
    invalidScac: 'SCAC invalides',
    noError: 'Pas d\'erreur',
    outOfQuota: 'Hors quota',
    recentUploads: 'Téléchargements récents',
    search: 'Recherche de connaissement, de numéro de conteneur, de transporteur ou d\'erreurs',
    title: 'Journaux de chargement du conteneur',
    uploadedDatetime: 'Date et heure de téléchargement',
    validationStatus: 'Statut de validation',
  },
  'dashboardPage': {
    containersAtPOD: 'Conteneurs au POD',
    containersAtPODToolTip: 'Jalons conteneurs au POD',
    containersAtTS: 'Conteneurs en T/S ',
    containersAtTSToolTip: 'Conteneurs actuellement au Transbordement',
    delay10OrMoreDays: '10+ jours',
    delay1To4Days: 'Délai supplémentaire 1-4 jours',
    delay5To9Days: '5-9 jours',
    delayedContainers: 'Conteneurs en retard',
    delayedContainersToolTip: 'Répartition des conteneurs arrivés en avance, à l\'heure ou en retard',
    departureDelayAtPOL: 'Retard de départ du POL',
    departureDelayAtPOLToolTip: 'Répartition du retard au départ du POL',
    incidentToolTip: 'Répartition des conteneurs en incidents',
    late1To2Days: 'Retard : 1-2 jours',
    late3To5Days: 'Fin : 3-5 jours',
    late5OrMoreDays: 'Retard : 5+ jours',
    problematicContainers: 'Conteneurs problématiques',
    problematicContainersToolTip: 'Répartition des conteneurs avec les problèmes suivants. Conteneur non suivi par le transporteur, aucune donnée du transporteur, conteneur non mappé avec BL, voyage précoce, port non planifié',
    title: 'Tableau de bord',
    topTradeLanesUsed: 'Principales voies commerciales utilisées',
    topTradeLanesUsedToolTip: 'Nombre de conteneurs qui partent de paires de ports uniques',
    totalBLs: 'Total des BL',
    totalBLsToolTip: 'BL uniques actuellement suivis par Portcast',
    totalContainers: 'Nombre total de conteneurs',
    totalContainersToolTip: 'Conteneurs uniques actuellement suivis par Portcast',
    totalContainersToolTipItalics: 'Retardé (en mer) + Arrivé (au POD) + Problématique',
    totalVesselVoyages: 'Total Navire / Voyages',
    totalVesselVoyagesToolTip: 'Navire/voyages uniques actuellement suivis par Portcast',
    waiting10OrMoreDays: '10+ jours',
    waiting5To10Days: '5-10 jours',
    waitingTime1To3Days: 'Temps d\'attente 1-3 jours',
    overview: 'Aperçu',
    exceptions: 'Exceptions',
    analytics: 'Analytique',
    viewAll: 'Voir tout',
    breakdownLateContainers: 'Répartition des conteneurs en retard',
    totalTracked: 'Total suivi',
    active: 'Actif',
    activeTooltip: 'Sous-ensemble de conteneurs actuellement suivis par Portcast',
    withError: 'Avec erreur',
    withErrorTooltip: 'Sous-ensemble de conteneurs pour lesquels il manque des données de suivi en raison d\'une erreur',
    inactive: 'Inactif/Archivé',
    inactiveTooltip: 'Sous-ensemble de conteneurs qui ne sont plus suivis. Les éléments suivants incluent les conteneurs qui ont été archivés manuellement ou par programmation (c\'est-à-dire 30 jours après la capture des derniers jalons d\'événement de conteneur).',
    totalActive: 'Actif total',
    journeyTBC: 'Voyage à confirmer',
    journeyTBCTooltip: 'Ces conteneurs sont trop tôt dans leur voyage et aucune donnée de suivi océanique n\'est disponible ; ou le transporteur n\'a pas confirmé le dernier tronçon du navire du voyage',
    arrivedAtPOD: 'Arrivé au POD',
    arrivedAtPODTooltip: 'Ces conteneurs sont arrivés avec succès au POD',
    journeyInProgress: 'Voyage en mer en cours',
    journeyInProgressTooltip: 'Ces conteneurs sont actuellement en transit vers le POD',
    withErrors: 'Avec des erreurs',
    carrierDataMissing: 'Données de transporteur manquantes',
    carrierDataMissingTooltip: 'Le transporteur ne renvoie aucune donnée. Ces envois peuvent être trop tôt ou ont déjà expiré. Et ensuite : Portcast vérifiera les données nouvelles ou mises à jour. Essayez de vérifier à nouveau dans 24 heures.',
    invalidInput: 'entrée invalide',
    invalidInputTooltip: 'Ceux-ci pourraient être des fautes de frappe. Les conteneurs suivants ne semblent pas appartenir aux numéros BL/de réservation qui ont été fournis.',
    unknownError: 'Erreur inconnue',
    unknownErrorTooltip: 'Une erreur interne s\'est produite lors du traitement de ces conteneurs. Et ensuite : nous enquêtons sur ces problèmes. Veuillez réessayer dans 12 heures.',
    commonReasonsForDelay: 'Raisons courantes de retard',
    portStay: 'Séjour au port',
    portStayTooltip: 'Cet incident s\'affiche lorsqu\'un navire/conteneur a passé plus de temps à attendre dans la zone portuaire avant l\'arrivée au port',
    anchorageStay: 'Séjour au mouillage',
    anchorageStayTooltip: 'Cet incident est affiché chaque fois qu\'un navire/conteneur a passé plus de temps à attendre dans la zone de mouillage avant l\'arrivée au port',
    nonOptimalRoute: 'Itinéraire non optimal',
    nonOptimalRouteTooltip: 'Cet incident est affiché lorsque le navire ne suit plus une route historiquement optimale',
  },
  'infiniteLoading': {
    noMore: 'Aucune autre donnée trouvée',
    noResults: 'Aucun autre enregistrement trouvé',
    spinner: 'Chargement ...',
  },
  'loginPage': {
    confirmNewPassword: 'Confirmer le nouveau mot de passe',
    confirmNewPasswordPlaceholder: 'Ré-entrez le nouveau mot de passe',
    emailInvalidError: 'Email invalide',
    emailPlaceholder: 'Entrez l\'e-mail enregistré',
    workEmailPlaceholder: 'Entrez votre e-mail professionnel',
    workEmailValidError: 'Veuillez utiliser votre adresse e-mail professionnelle pour vous inscrire.',
    emailRequiredError: 'L\'e-mail est requis',
    firstName: 'Prénom',
    firstNameEnter: 'Entrez votre prénom',
    forgotPassword: 'Mot de passe oublié',
    lastName: 'Nom de famille',
    lastNameEnter: 'Entrer le nom de famille',
    login: 'Connexion',
    newPassword: 'nouveau mot de passe',
    newPasswordEnter: 'Entrez un nouveau mot de passe',
    passwordDoNotMatch: 'Les mots de passe ne correspondent pas',
    passwordInvalidError: 'Mot de passe incorrect',
    passwordPlaceholder: 'Entrer le mot de passe',
    passwordRequiredError: 'Mot de passe requis',
    passwordResetSuccess: 'Réinitialisation du mot de passe réussie ! Veuillez essayer de vous connecter',
    resetPassword: 'réinitialiser le mot de passe',
    sendResetCode: 'Envoyer le code de réinitialisation',
    title: 'Connectez-vous à',
    verificationCode: 'Le code de vérification',
    verificationCodeEnter: 'Entrez le code de vérification',
    verificationCodeRequired: 'Le code de vérification est requis',
    welcome: 'Bienvenue à',
    signUp: 'S\'inscrire',
    signUpTitle: 'Créez votre compte Portcast',
    signUpDescription: 'Entrez votre e-mail professionnel. Les informations d\'identification et le mot de passe de votre compte seront envoyés à cet e-mail.',
    registeredSuccessfully: 'Enregistré avec succès',
    signUpMessage: 'Création de compte en cours ! Vous aurez de nos nouvelles dans les 24 heures. En attendant, regardez notre vidéo produit pour avoir une idée de ce que nous proposons.',
  },
  'portLookupPage': {
    code: 'Code',
    country: 'Pays',
    countryCode: 'Code postal',
    latitude: 'Latitude',
    longitude: 'Longitude',
    ports: 'Ports',
    search: 'Rechercher des noms de port ou des codes de port',
  },
  'profilePage': {
    masterOrg: 'Organisation Maîtresse',
    noOfUsers: 'Nombre d\'utilisateurs',
    orgApiKey: 'Clé API de l\'organisation',
    orgIdInfo: ' Incluez l\'identifiant de l\'organisation dans le modèle de téléchargement d\'e-mail. Téléchargez un exemple de modèle ci-dessous.',
    organisationApiKey: 'Clé API de l\'organisation',
    organizationId: 'Identifiant de l\'organisation',
    organizationName: 'nom de l\'organisation',
    organizations: 'Organisations',
    quotaUtilization: 'Utilisation des quotas',
    searchOrganizations: 'Rechercher des organisations',
    title: 'Profil de l\'utilisateur',
    userApiKey: 'Clé API utilisateur',
    userDetails: 'Détails de l\'utilisateur',
    userID: 'Identifiant d\'utilisateur',
    viewDetails: 'Voir les détails',
    autoArchive: 'Archive automatique',
    addCustomField: 'Ajouter un champ personnalisé',
    create: 'Créer',
    fieldName: 'Nom de domaine',
    fieldType: 'Type de champ',
    allowMultipleValues: 'Autoriser plusieurs valeurs',
    subFields: 'Sous-champs',
    newCustomField: 'Nouveau champ personnalisé',
  },
  'singleContainerPage': {
    billOfLadingNo: 'Connaissement non',
    copyUrl: 'Copier le lien',
    destination: 'Destination',
    detailedEvents: 'Événements détaillés',
    goBack: 'Retourner',
    historical: 'HISTORIQUE',
    incidents: 'Incidents',
    lastUpdated: 'Dernière mise à jour (UTC)',
    location: 'Emplacement',
    mapView: 'Vue de la carte',
    modeOfTransport: 'Mode de transport',
    ooops: 'Oups !',
    predicted: 'PRÉVU',
    predictedActual: 'Prévu */Réel',
    resetZoom: 'Réinitialiser le zoom',
    somethingWentWrong: 'Algo salió mal',
    source: 'La source',
    conversations: 'Conversations',
    noMessageFound: 'Aucune conversation trouvée pour ce conteneur',
    messagePlaceholder: 'Ajoutez votre commentaire ici... Utilisez @ pour identifier les personnes. Un mail leur sera envoyé',
    noMessageFoundError: 'Une erreur s\'est produite lors de la récupération des conversations. Veuillez réessayer.',
    fetchingMessages: 'Récupération des conversations...',
    tryAgainLater: 'Veuillez réessayer ultérieurement',
  },
  'vesselsLookupPage': {
    breadthExtreme: 'Largeur extrême',
    callSign: 'Signe d\'appel',
    deadWeight: 'Poids mort',
    draught: 'Brouillon',
    engineBuilder: 'Constructeur de moteurs',
    enginePower: 'Puissance du moteur',
    engineStroke: 'Course du moteur',
    grossTonnage: 'Jauge brute',
    search: 'Rechercher des navires',
    speed: 'La rapidité',
    type: 'Taper',
    vessels: 'Navires',
  },
  'navBar': {
    'control_tower_dashboard': 'Tour de contrôle',
    'co2_report_dashboard': 'Rapport CO2',
    'port_congestion': ' La congestion portuaire',
    'historicalTransit': 'Temps de transport',
    'dashboard': 'Tableau de bord de contrôle',
    'exception_dashboard': 'Des exceptions',
    'historical_transit_time': 'Temps de transit historique',
    'accuracy_dashboard': 'Précision',
    'latency_accuracy_dashboard': 'Couverture et latence',
    'vessel-tracking': 'Suivi des navires',
    'report-dashboard': 'Générateur de rapports',
    'report-builder': 'Générateur de rapports',
    'login': 'Connexion',
    'profile': 'Page de profil : {initiales}',
    'container-tracking': 'Suivi des conteneurs',
    'container-tracking-id': '{ID de conteneur}',
    'container-tracking-id-email': '{containerId} - Suivi d\'un conteneur unique',
    'container-tracking-master-report': 'Rapport principal',
    'container-tracking-api': 'Réponse API',
    'container-tracking-cached': 'Signet mis en cache',
    'container-tracking-typesense': 'Objet d\'index de recherche (Typesense)',
    'upload-logs-container': 'Journaux de conteneur',
    'upload-logs-vessel': 'Journaux du navire',
    'sailing-schedule': 'Horaires de navigation',
    'metadata_ports': 'Métadonnées des ports',
    'metadata_carriers': 'Métadonnées du transporteur',
    'metadata_vessels': 'Métadonnées du navire',
  },
  'onBoarding': {
    title: 'Commencer',
  },
  'Arrived at POD': 'Arrivé au POD',
  'Arrived at T/S': 'Arrivé à T/S',
  'Departed from POL': 'Départ de POL',
  'Departed from T/S': 'Départ de T/S',
  'Discharged at POD': 'Libéré au POD',
  'Discharged at T/S': 'Déchargé à T/S',
  'Empty Return': 'Retour vide',
  'Gate In Full': 'Porte en entier',
  'Gate Out Empty': 'Porte de sortie vide',
  'Gate Out Full': 'Gate Out complet',
  'Loaded at POL': 'Chargé à POL',
  'Loaded at T/S': 'Chargé à T/S',
  'v2': {
    sideNav: {
      addContainers: 'Ajouter des conteneurs',
      addContainer: 'Ajouter un conteneur',
      addContainersSubtitle: '{count} crédits restants',
      creditMinimumWarningModalText: 'Votre limite de crédit actuelle approche. Pour une augmentation, veuillez nous contacter.',
      creditZeroErrorModalText: 'Votre limite de crédit actuelle est épuisée. Vous ne pouvez plus télécharger de conteneurs supplémentaires. Pour demander une augmentation, veuillez nous contacter. ',
      dashboard: 'Aperçu',
      tracking: 'Suivi',
      vesselTracking: 'Suivre le navire',
      containerTracking: 'Suivre le conteneur',
      reports: 'Rapports',
      portcast_indices: 'Indices de portcast',
      portCongestion: 'La congestion portuaire',
      developerTools: 'Outils de développement',
      containerLogs: 'Journaux de conteneur',
      vesselLogs: 'Journaux du navire',
      carrierLookup: 'Recherche de transporteur',
      portLookup: 'Recherche de port',
      vesselLookup: 'Recherche de navire',
      helpCenter: 'Suivre le navire',
      knowledgeBase: 'Base de connaissances',
      reportAnIssue: 'Signaler un problème',
      changeLog: 'Journal des modifications',
      apiDocs: 'Documents d\'API',
      profile: 'Mon profil',
      logout: 'Se déconnecter',
    },
    singleContainerPage: {
      plannedArrival: 'Initialement prévu',
      originalETA: 'ETA d\'origine',
      latestETA: 'Dernière programmation',
      predictedETA: 'ETA prévue',
      downloadReport: 'Télécharger le rapport',
      download: 'Télécharger',
      shareURL: 'URL de partage',
      share: 'Partager',
      shareURLToolTip: 'Cliquez ici pour copier l\'URL',
      shareURLCopyMessage: 'URL copiée dans le presse-papiers',
      seeRelatedShipments: 'Voir les livraisons associées',
      seeAllShipmentsOnVessel: 'Voir toutes les expéditions sur le navire',
      noRelatedShipments: 'Aucun autre conteneur trouvé sous ce numéro de B/L',
      viewContainerDetails: 'Afficher les événements détaillés',
      blNo: 'Numéro de connaissement',
      newUI: 'Nouvelle interface utilisateur',
      oldUI: 'Ancienne interface utilisateur',
      tabs: {
        detailedEvents: {
          title: 'Événements détaillés',
        },
        productDetails: {
          title: 'détails du produit',
        },
        terminalDetails: {
          title: 'Détails du terminal',
        },
        conversations: {
          title: 'Conversations',
        },
      },
      arrivalAtPOD: 'Arrivée au POD',
      carrierArrival: 'Arrivée du transporteur',
      blNumber: 'Numéro de connaissement',
      carrierLatestVessel: 'Transporteur et dernier navire',
    },
    noLoginBanner: {
      title: 'Rejoignez Portcast pour obtenir',
      point1: 'Toutes les données de suivi des conteneurs Carrier en un seul endroit',
      point2: 'Fini le suivi manuel pour vos équipes',
      point3: 'Mises à jour automatisées de votre e-mail et de votre mobile',
      requestAccess: 'Demande d\'accès',
    },
    bulkUpload: {
      containerBulkUpload: 'Méthode 1 : ajouter des conteneurs en masse',
      containerManuallyUpload: 'Méthode 2 : ajouter des conteneurs manuellement',
      bulkUploadText: 'Déposez vos fichiers ici ou cliquez ici pour ajouter des fichiers',
      uploadSupportFile: 'Nous prenons en charge les formats .CSV, .XLS et .XlSX',
      downloadSampleUploadFile: 'Télécharger un exemple de fichier de téléchargement',
      addManuallyContainers: 'Cliquez ici pour ajouter des conteneurs manuellement',
      addingManuallyTakeMoreTime: 'Les ajouter manuellement prend généralement plus de temps',
      mapColumns: 'Mappez les colonnes de votre fichier téléchargé avec les champs ci-dessous',
      addContainersManually: 'Ajouter des conteneurs manuellement',
    },
  },
  'welcomeDialog': {
    title: 'Bienvenue à bord !!',
    description: 'Pour commencer à suivre vos conteneurs, la première étape consiste à les ajouter. Pour démarrer le processus de téléchargement, cliquez simplement sur le bouton "Ajouter un conteneur".',
    processingTitle: 'Traitement des téléchargements...',
    processingDescription: 'Cela peut prendre quelques minutes. Nous vous informerons une fois les conteneurs téléchargés avec succès.',
  },
  'co2EmissionLockDialog': {
    title: 'Émissions de CO2e verrouillées',
    description: 'Les émissions de CO2 sont actuellement bloquées pour votre compte. Contactez-nous pour débloquer les données d’émission.',
  },
  'predictionsLockDialog': {
    title: 'Prédictions verrouillées',
    description: 'Souhaitez-vous débloquer les prédictions pour la visibilité des conteneurs ? Les prévisions sont plus précises et actualisées chaque jour. Contactez-nous pour débloquer les prédictions.',
  },
}
