export const zh_TW = {
  'carrierLookupPage': {
    carriers: '運營商',
    fullName: '全名',
    search: '搜索運營商或 SCAC',
    synonyms: '同義詞',
  },
  'common': {
    showJson: '顯示 Json',
    carrierTime: '營運商時間',
    dashboard: '儀表板',
    requestReceived: '已收到請求',
    viewTransitTimeDashboard: '查看歷史運輸時間',
    requestReceivedMessage: '感謝您與我們聯繫。我們的團隊將在接下來的 48 小時內與您聯繫。',
    noThanks: '不，謝謝',
    contactUs: '聯絡我們',
    contactUsToUnlock: '聯絡我們解鎖',
    dataUnavailable: '數據不可用',
    co2Emission: '二氧化碳排放量',
    emission: '排放',
    locked: '鎖定',
    predictionLocked: '預測鎖定',
    preferences: '優先',
    enableNewUI: '啟用新用戶界面',
    iAccept: '我接受',
    termsOfUse: '使用條款',
    openInNewWindow: '在新窗口中打開',
    unavailable: '不可用',
    availableSoon: '即將上市',
    send: '發送',
    portCongestion: '港口擁堵',
    developerTooling: '開發者工具',
    activeOrg: '活躍組織',
    actual_arrival: '實際到貨',
    added_on: '添加在',
    anchorageStay: '安克雷奇住宿',
    apiDocumentation: 'API 文檔',
    archive: '檔案',
    archived: '存檔',
    arrival_status: '到達狀態',
    arrive_at_pod: '抵達 POD',
    arrive_at_pot: '到達 POT',
    arrived: '到達的',
    arriving: '到達',
    ts_waiting_time_in_days: 'TS等待時間（天）',
    is_transhipment: '到達',
    bls: 'bls',
    booking_no_: '預訂號',
    byCarrier: '由承運人',
    cancel: '取消',
    carriers: '運營商',
    confirm: '確認',
    confirmMessage: '您確定要繼續嗎？',
    consignee: '收貨人',
    container: '容器',
    containerNotMappedWithBL: '容器未映射到 BL',
    containerNotTrackedByCarrier: '承運人未跟踪集裝箱',
    containers: '容器',
    contents: '內容',
    copied: '已復制',
    copy: '複製',
    count: '數數',
    days: '天',
    delay_in_days: '延遲天數',
    delete: '刪除',
    depart_at_pol: '在 POL 出發',
    depart_at_pot: '在 POT 出發',
    direct: '直接的',
    discharge: '釋放',
    discharged: '出院',
    done: '完畢',
    download: '下載',
    downloadAll: '全部下載',
    early: '早期的',
    earlyJourney: '早期旅程',
    edit: '編輯',
    email: '電子郵件',
    emptyReturn: '空返回',
    error: '錯誤',
    errors: '錯誤',
    events: '事件',
    exceptions: '控制儀表板',
    from: '從',
    gateOut: '門出',
    gatein_at_pol: '進入 POL',
    gateout_at_pod: '在 POD 出閘',
    gateout_at_pol: '在 POL 出閘',
    importer: '進口商',
    inThis: '在這',
    incidentList: {
      anchorageStay: '安克雷奇住宿',
      nonOptimalRoute: '非最優路線',
      outdatedSchedule: '過時的時間表',
      portStay: '港口住宿',
    },
    helpCenter: '幫助中心',
    incidents: '事件',
    init: '在裡面',
    internal_ref_no_: '內部參考。不。',
    late: '晚的',
    load_at_pol: '負載在 POL',
    load_at_pot: '在 POT 加載',
    loading: '正在加載...',
    logout: '登出',
    logs: '日誌',
    lookup: '抬頭',
    new: '新的',
    noDataFromCarrier: '沒有來自運營商的數據',
    nonOptimalRoute: '非最優路線',
    of: '的',
    okay: '好的',
    onTime: '準時',
    others: '其他',
    or: '或者',
    organization: '組織',
    outdatedSchedule: '過時的時間表',
    p_o_no_: '郵政信箱不。',
    password: '密碼',
    pod: '莢',
    pol: '波蘭',
    pol_waiting_time_in_days: 'POL 等待時間（天）',
    portNotInSchedule: '港口不在計劃中',
    portStay: '港口住宿',
    ports: '端口',
    predicted_arrival: '預計到達',
    proceed: '繼續',
    refreshDataConfirmMessage: '確定要刷新 {total} 個容器數據嗎？',
    reindexDataConfirmMessage: '您確定要重新索引數據嗎？',
    releaseNotes: '支持台',
    return_at_pod: '在 POD 返回',
    save: '節省',
    searchOrgs: '搜索組織',
    selected: '已選中',
    showAll: '顯示所有',
    status: '地位',
    statusList: {
      'Arrived': '到達的',
      'NO_DATA_RECEIVED_FROM_CARRIER': '運營商沒有數據',
      'BL_NOT_FOUND': '未找到 BL',
      'BULK_INIT': '準備預測',
      'CONTAINER_IDLE_AFTER_GATEOUT': '關門後集裝箱閒置',
      'CONTAINER_NOT_FOUND': '找不到容器',
      'CONTAINER_NOT_MAPPED_WITH_BL': '容器未映射到 BL',
      'CONTAINER_RETURNED_TO_CARRIER': '集裝箱退回承運人',
      'INIT': '準備預測',
      'NO_DATA_FROM_PROVIDER': '沒有來自運營商的數據',
      'On Time': '準時',
      'Preparing Predictions': '準備預測',
      'SUCCESS': '成功',
      'SUCCESS_EARLY_JOURNEY': '早期旅程',
      'EARLY_JOURNEY': '早期旅程',
      'TARGET_PORT_NOT_IN_ROTATION': '港口不在計劃中',
      'earlyByXDays': '提前 {days} 天到達',
      'lateByXDays': '遲到 {days} 天',
      'delayByDays': '延遲 {days} 天',
    },
    status_code: '狀態碼',
    stop: '停止',
    stops: '停止',
    supplier: '供應商',
    switchActiveOrg: '切換活動組織',
    switchTab: '現在請切換到另一個標籤',
    t_m_s_ref_no_: 'TMS 參考。不。',
    t_s: 'T/S',
    thereAreNo: '沒有',
    unique: '獨特的',
    unknownError: '未知錯誤',
    unload_at_pod: '在 POD 卸載',
    unload_at_pot: '在 POT 卸貨',
    updatedSuccessfully: '更新成功',
    updated_on: '更新時間',
    uploadLogs: '上傳日誌',
    vessel: '血管',
    vesselTracking: '船隻追踪',
    vessels: '船隻',
    viewProfile: '查看資料',
    makeAsDefault: '設為默認',
    containerNumber: '貨櫃號',
    billNumber: '提單號',
    organisation: '組織',
    addedUpdated: '添加和更新',
    exceptionsIncidents: '例外與事件',
    poNumber: '訂單號',
    tmsRefNumber: 'TMS 參考編號',
    content: '內容',
    tableView: '表視圖',
    calenderView: '日曆視圖',
    mapView: '地圖視圖',
    customLate: '自定義延遲',
    viewName: '查看名稱',
    viewDescription: '查看說明',
    optional: '選修的',
    setAsDefaultView: ' 設置為默認視圖。',
    portOfLoading: '裝貨港',
    transhipment: '轉運',
    transhipments: '轉運',
    portOfDeparture: '卸貨港',
    search: '搜索...',
    routeChange: '路線變更',
    anchorageDelay: '錨地延遲',
    portSequenceChange: '端口順序改變',
    carrierConsideringDelay: '承運人考慮延誤',
    weatherAdversity: '天氣逆境',
    delayAtUpcomingPort: '即將到來的港口延誤',
    carrierDataMissing: '運營商數據丟失',
    invalidInput: '輸入無效',
    remove: '消除',
    filters: '過濾器',
    loadingPleaseWait: '加載請稍候',
    original: '原來的',
    portcast: '端口廣播',
    clearAll: '全部清除',
    active: '積極的',
    bookmarked: '已收藏',
    bookmark: '書籤',
    filterAllMessage: 'All：所有容器，包括 Active、Archived 和 With Error。',
    filterActiveMessage: '活動：主動跟踪的容器。此列表不包括有錯誤或已存檔的容器。',
    filterNewMessage: '新：最近 24 小時內上傳的容器。',
    filterLateMessage: '遲到：所有遲到的活動容器。',
    filterArrivedMessage: '已到達：以下容器已到達 POD。',
    filterBookmarkedMessage: '已添加書籤：以下容器已被該組織中的一位用戶添加為書籤。',
    selectAllContainers: '選擇所有 {count} 個容器',
    scheduleAlertMessage: '您要為這些容器安排警報嗎？接收有關發送到您的電子郵件或手機號碼的關鍵容器里程碑的通知',
    scheduleAlerts: '安排提醒',
    dontSchedule: '不安排',
    bookmarkContainerMessage: '您要為這些容器添加書籤嗎？它將幫助您確定重要容器的優先級。',
    bookmarkContainers: '書籤容器',
    dontBookmark: '不要收藏',
    downloadContainerMessage: '您要下載容器嗎？',
    downloadVesselMessage: '您想下載船隻嗎？',
    download300ContainerMessage: '您要下載選定的容器嗎？選擇超過 300 個容器可能需要更長的時間來處理。',
    download300VesselMessage: '您想下載選定的船隻嗎？選擇超過 300 艘船舶可能需要更長的時間來處理。',
    dontDownload: '取消',
    shareViaEmail: '通過電子郵件分享',
    downloadNow: '現在下載',
    archiveContainerMessage: '您要歸檔容器嗎？歸檔將停止對選定容器的跟踪。',
    archiveContainer: '歸檔容器',
    dontArchive: '不存檔',
    deleteContainerMessage: '刪除容器是永久性的。它將從您的列表中刪除。',
    deleteVesselsMessage: '刪除船隻是永久性的。它將從您的列表中刪除。',
    deleteContainer: '刪除容器',
    deleteVessel: '刪除船隻',
    dontDelete: '不要刪除',
    eta: '預計到達時間',
    carrierVessel: '承運人/船舶',
    action: '行動',
    searchByContainer: '按容器、提單或採購訂單編號搜索',
    searchByPodPolTs: '按 POL、POD 或 T/S 搜尋',
    searchByVesselCarrier: '按船舶或承運人搜索',
    searchByCustomer: '按客戶搜尋',
    searchByVessel: '按船號或提單號搜索',
    applyGroupBy: '申請分組依據',
    applySort: '應用排序',
    showContainers: '顯示容器',
    switchOrganisation: '切換組織',
    showCountContainers: '顯示 {count} 個容器',
    showCountVessels: '顯示 {count} 艘船隻',
    applyFilters: '應用過濾器',
    saveView: '保存視圖',
    savedViews: '保存的視圖',
    selectView: '選擇視圖',
    addNew: '添新',
    chooseView: '選擇視圖',
    applyView: '應用視圖',
    asDefault: '默認',
    set: '放',
    viewNameError: '視圖名稱已存在',
    lateByDays: '遲到 {start} 至 {end} 天',
    lateByDaysNumber: '遲到 {count}+ 天',
    latestEvents: '最新活動',
    masterOrg: '主組織',
    emptyGateOutAtPOL: '空門輸出（在 POL）',
    gateInFullAtPOL: '全門（在 POL）',
    loadedAtPOL: '已加載（在 POL）',
    departedFromPOL: '出發（來自 POL）',
    arrivalAtPOD: '到達（POD）',
    dischargedAtPOD: '出院（在 POD）',
    gateOutFullAtPOD: 'Gate Out Full（在 POD）',
    emptyReturnAtPOD: '清空返回（在 POD）',
    lateStatus: '遲到狀態',
    created: '已創建',
    updated: '更新',
    aisSatellite: 'AIS 衛星數據',
    containerNotFound: '未找到容器。<br>嘗試刪除一些過濾器。',
    vesselsNotFound: '未找到船隻。<br>嘗試刪除一些過濾器。',
    comingSoon: '即將推出',
    pleaseTryAgain: '請稍後再檢查並報告進一步調查',
    port: '港口',
    country: '國家',
    pol_country: '波爾國家',
    pod_country: '豆莢國',
    clickToCopy: '點擊複製',
    clickToCopyContainer: '點擊複製集裝箱號',
    generatingPrediction: '生成預測',
    latest: '最新的',
    dontHaveAnAccount: '還沒有帳戶？',
    alreadyHaveAnAccount: '已經有帳戶？',
    corporateEmailInvalidError: '請使用企業郵箱進行註冊。',
    noOrgDialogTitle: '聯繫管理部門',
    noOrgDialogDescription: '您沒有分配組織，請聯繫管理人員。',
    thankYou: '謝謝',
    needsAttention: '需要注意',
    onTheWay: '在途中',
    targetPort: '目標端口',
    targetPortEta: '目標港口埃塔',
    PredictedActual: '預測 * / 實際',
    scheduledArrival: '如期到來',
    errorCode: '錯誤代碼',
    flaggedOn: '標記為開啟',
    showVessels: '顯示船隻',
    selectAllVessels: '選擇所有 {count} 艘船隻',
    restart: '重新開始',
    reIndexContainers: '重新索引容器',
    reIndexMessage: '您確定要重新索引容器數據嗎？',
    refresh: '刷新',
    refreshVessels: '刷新船隻',
    refreshVesselsMessage: '您確定要刷新 {count} 艘船舶數據嗎？',
    downloadSuccess: '容器下載成功。',
    somethingWentWrong: '出了些問題。請稍後再試。',
    uploadingLimitExceed: '您的上傳配額已超出',
    containerUploadedSuccessfully: '容器上傳成功',
    reportType: '報告類型',
    timeRange: '時間範圍',
    selectFields: '選擇字段',
    departingContainers: '出發貨櫃',
    departedContainers: '離開的貨櫃',
    arrivingContainers: '抵達貨櫃',
    arrivedContainers: '貨櫃抵達',
  },
  'containerPage': {
    actual: '實際的',
    actualArrivalAscending: '實際到達 - 升序',
    actualArrivalDescending: '實際到達 - 降序',
    addContainer: '添加容器',
    addContainers: '添加容器',
    addNewContainers: '新增容器',
    addMoreContainers: '添加更多容器',
    addedOn: '添加在',
    addedOnMasterOrg: '組織/添加在',
    addedOnSortByItems: {
      created_asc: '已創建 - 升序',
      created_desc: '已創建 - 降序',
    },
    allTab: '全部',
    archiveContainers: '存檔容器',
    archivedTab: '存檔',
    arrival: '到達',
    arrivalSortByItems: {
      pod_actual_arrival_lt_asc: '實際到達 - 升序',
      pod_actual_arrival_lt_desc: '實際到達 - 降序',
      pod_portcast_predicted_arrival_lt_asc: '預計到達 - 上升',
      pod_portcast_predicted_arrival_lt_desc: '預計到達 - 下降',
    },
    arrivalStatus: '到達狀態',
    arrivedAtPOD: '到達 POD',
    arrivedAtTS: '到達 T/S',
    arrivedTab: '到達的',
    arrivingTab: '到達',
    billOfLading: '提貨單',
    bookingInfo: '預訂信息。',
    bookingInformation: '預訂信息',
    bookingNo: '預訂號',
    bookingNumber: '訂單號',
    browseFiles: '瀏覽文件',
    bulkUpload: '批量上傳',
    carrier: '載體',
    carrierNo: '承運人編號',
    carrierSCAC: '承運人/SCAC',
    carrierSCACVessels: '承運人 SCAC / 船舶',
    clear: '清除',
    clearAppliedFilters: '清除應用過濾器',
    consignee: '收貨人',
    containerArchiveSuccessful: '成功歸檔 {count} 個容器！！',
    containerEvent: '容器事件',
    containerNo: '集裝箱號',
    containerNoBl: '貨櫃號/提單',
    containerNotMappedWithBL: '容器未映射到 BL',
    containerNotTrackedByCarrier: '承運人未跟踪集裝箱',
    containerUploadErrorDuplicate: '重複上傳嘗試',
    containerUploadErrorInvalidContainer: '無效的容器編號',
    containerUploadErrorInvalidCarrier: '運營商編號無效',
    containerUploadErrorQuotaExceeded: '超過配額',
    containerUploadErrorMissingCarrier: '缺少運營商',
    containerUploadErrorMissingContainer: '缺少容器編號',
    contentBrand: '內容/品牌',
    contents: '內容',
    contractualOriginalETA: '合同/原始預計到達時間',
    contractualOriginalETD: '合同/原始 ETD',
    customer: '顧客',
    deletedAllInvalidText: '您已刪除所有無效容器',
    departedFromPOL: '離開POL',
    departedFromTS: '從 T/S 出發',
    departure: '離開',
    destination: '目的地',
    discharge: '釋放',
    dischargedAtPOD: '在 POD 出院',
    dischargedAtTS: '在 T/S 處卸貨',
    disclaimer: '基於最初由承運人安排的日期',
    downloadAll: '下載所有容器',
    downloadContainers: '下載容器',
    downloadSample: '下載示例 CSV',
    downloadSelected: '下載選定的容器',
    dropFilesHere: '將文件放在這裡',
    earlyJourney: '早期旅程',
    earlyTab: '早期的',
    emptyReturn: '空返回',
    enterData: '在此處輸入您的數據',
    errorCodeFlaggedByTab: '錯誤代碼/標記者',
    exportErrorCSV: '將錯誤導出為 CSV',
    fileSupport: '我們支持 .CSV、.XLS 和 .XlSX',
    filterBookingNo: '按預訂號碼過濾',
    filterByBLs: '按 BL 過濾',
    filterByCarriers: '按運營商篩選',
    filterByContainerNos: '按容器編號過濾',
    filterByPODPorts: '按 POD 端口過濾',
    filterByPOLPorts: '按 POL 端口過濾',
    filterByTSPorts: '按 TS 端口過濾',
    filterByVessels: '按船隻過濾',
    filterConsignee: '按收貨人篩選',
    filterContents: '按內容過濾',
    filterImporter: '按進口商篩選',
    filterInternalRef: '按內部參考編號過濾',
    filterPoNo: '按郵政編碼過濾',
    filterSupplier: '按供應商篩選',
    filterTmsRef: '按 T.M.S 參考編號過濾',
    finalVesselName: '最終船名',
    firstVesselName: '第一船名',
    flaggedOnTab: '標記為',
    gateInFull: '滿門',
    gateOutEmpty: '門出空',
    gateOutFull: '門輸出滿',
    generateNewPredictions: {
      disabledTitle: '為到達的容器禁用生成預測',
      subTitle: '我們將為您提供集裝箱的新 ETA',
      successMessage: '產生新的預測。請在 1-2 分鐘後回來查看',
      title: '生成新的預測',
    },
    groupBy: '通過...分組',
    groupingByUnique: '按唯一身份分組',
    importer: '進口商',
    initiallyScheduled: '最初計劃',
    plannedArrival: '最初預定',
    planned: '最初的',
    internalRef: '內部參考',
    internalRefNumber: '內部參考號',
    invalidContainerNo: '無效的貨櫃號',
    journey: '旅行',
    lateTab: '晚的',
    latestEvent: '最新事件',
    latestPredicted: '最新（預測）',
    latestScheduled: '最新預定',
    loadedAtPOL: '裝在 POL',
    loadedAtTS: '在 T/S 加載',
    loading: '正在加載',
    mapColumns: '地圖列',
    mapColumnsHeader: '所選行是否包含列標題？',
    mapColumnsTip: '從文件中選擇對應的列',
    miscellaneousInformation: '雜項信息',
    newTab: '新的',
    nextStepsInstruction: '下一步將允許您將電子表格列與正確的數據點相匹配。',
    noDataFromCarrier: '沒有來自運營商的數據',
    noInvalidContainers: '沒有無效的容器。',
    noSailingInfo: '沒有航行信息',
    outboundHaulage: '出境運輸',
    outboundHaulageID: '出境運輸 ID',
    packageQuantity: '包裝數量',
    poNo: '郵政編碼',
    poNumber: '訂單號',
    pod: '莢',
    pol: '波蘭',
    portNotinSchedule: '港口不在計劃中',
    predicted: '預料到的',
    predictedActualArrival: '預計/實際到貨',
    predictedArrivalAscending: '預計到達 - 上升',
    predictedArrivalDescending: '預計到達 - 下降',
    preparingPredictions: '準備預測',
    productDescription: '產品描述',
    productDetails: '產品詳情',
    productInfo: '產品信息。',
    productInformation: '產品信息',
    redFlagComments: '輸入評論，或任何您想添加的內容',
    redFlagContainer: '紅旗集裝箱',
    redFlaggedSuccessfulMessage: '已成功標記 {count} 個容器。您還可以在紅色標記選項卡下找到它們',
    redFlaggedTab: '紅色標記',
    refreshOrgData: '刷新數據',
    reindexData: '重新索引數據',
    reviewContainers: '審查容器',
    sailingDates: '航行日期',
    sailingInfo: '航行信息',
    sailingInfoNotAvailable: '由於缺少 POL 或 POD 信息，航行信息不可用。',
    saleInfo: '銷售信息',
    salesOrder: '銷售訂單',
    scheduled: '預定的',
    searchContainers: '搜索容器',
    selectAnotherRow: '選擇另一行',
    selectReasonForRedFlag: '選擇舉報原因',
    showEtaHistory: '顯示 ETA 歷史',
    sortBy: '排序方式',
    sortByAddedOnDate: '排序方式添加',
    sortByArrivalDate: '按到達日期排序',
    source: '資源',
    sourceFile: '源文件',
    status: '地位',
    statusIncidents: '狀態/事件',
    subtextAddContainers: '您可以在此處從源文件中復制和粘貼多行。',
    success: '成功',
    supplier: '供應商',
    supplierID: '供應商編號',
    title: '集裝箱追踪',
    tmsRef: 'T.M.S 參考',
    tmsReferenceNumber: 'TMS 參考編號',
    ts: 'TS',
    unflagContainers: '取消標記容器',
    updatedOn: '更新時間',
    uploadAddContainers: '上傳/添加容器',
    uploadCompleted: '上傳完成',
    uploadFailed: '上傳失敗',
    uploadFailedMessage: '上傳容器失敗。',
    uploadMessage1: '您已上傳',
    uploadMessage2: '容器成功',
    vessel: '血管',
    vesselNotConfirmed: '船隻未確認',
    vesselNotConfirmedTooltip: '承運人尚未確認此航段的船名。 48 小時後再檢查',
    vesselVoyage: '船名航次',
    viewRecentUploadLogs: '查看最近上傳的容器日誌',
    search: '搜索',
    origin: '起源',
    selectPort: '選擇端口',
    selectCountry: '選擇國家',
    addingContainerInProgress: '正在新增容器...',
    AddCountContainers: '新增 {count} 個容器',
    new: '新的',
    createReport: '建立報告',
  },
  'containerUploadLogsPage': {
    all: '全部',
    billOfLadingNo: '提單號',
    carrierScac: '承運人 SCAC',
    containerNo: '集裝箱號。',
    duplicates: '重複',
    error: '錯誤',
    invalidContainers: '無效的容器',
    invalidPayload: '無效負載',
    invalidScac: '無效的 SCAC',
    noError: '沒有錯誤',
    outOfQuota: '超出配額',
    recentUploads: '最近上傳',
    search: '搜索提貨單、集裝箱號、承運人或錯誤',
    title: '容器上傳日誌',
    uploadedDatetime: '上傳日期時間',
    validationStatus: '驗證狀態',
  },
  'dashboardPage': {
    containersAtPOD: 'POD 的容器',
    containersAtPODToolTip: 'POD 的容器里程碑。使用此小部件來識別清除特定里程碑的容器',
    containersAtTS: 'POT 的停留時間',
    containersAtTSToolTip: '活動容器在 POT 上花費的時間。根據 POT 的加載和卸載事件之間的差異計算。請注意 10 天以上存儲桶中的容器，因為它們有被翻車的風險',
    delay10OrMoreDays: '10 天以上',
    delay1To4Days: '等待時間 1-4 天',
    delay5To9Days: '5-9 天',
    delayedContainers: '延遲容器',
    delayedContainersToolTip: '提前、準時或遲到的集裝箱配送',
    departureDelayAtPOL: '在 POL 的停留時間',
    departureDelayAtPOLToolTip: '活動容器在 POL 上花費的時間。根據 POL 上的加載事件和完全事件中的門之間的差異計算。請注意 10 天以上存儲桶中的容器，因為它們有面臨 D&D 費用的風險',
    incidentToolTip: '發生事故的集裝箱分佈',
    late1To2Days: '遲到：1-2 天',
    late3To5Days: '遲到：3-5 天',
    late5OrMoreDays: '遲到：5 天以上',
    problematicContainers: '有問題的容器',
    problematicContainersToolTip: '有以下問題的容器分佈。承運人未跟踪集裝箱，承運人無數據，集裝箱未與提單映射，早期旅程，港口未按期',
    title: '控制儀表板',
    topTradeLanesUsed: '使用最多的貿易通道',
    topTradeLanesUsedToolTip: '來自唯一端口對的容器計數',
    totalBLs: '總提單',
    totalBLsToolTip: 'Portcast 目前正在跟踪的唯一 BL',
    totalContainers: '容器總數',
    totalContainersToolTip: 'Portcast 當前正在跟踪的唯一容器',
    totalContainersToolTipItalics: '延遲（在海上）+ 到達（在 POD）+ 有問題',
    totalVesselVoyages: '船舶總數/航次',
    totalVesselVoyagesToolTip: 'Portcast 目前正在跟踪的獨特船隻/航次',
    waiting10OrMoreDays: '10 天以上',
    waiting5To10Days: '5-10 天',
    waitingTime1To3Days: '等待時間 1-3 天',
    overview: '概述',
    exceptions: '例外',
    analytics: '分析',
    viewAll: '查看全部',
    breakdownLateContainers: '後期集裝箱分解',
    totalTracked: '總跟踪',
    active: '積極的',
    activeTooltip: 'Portcast 當前正在跟踪的容器子集',
    withError: '有錯誤',
    withErrorTooltip: '由於錯誤而缺少跟踪數據的容器子集',
    inactive: '非活動/存檔',
    inactiveTooltip: '不再被跟踪的容器子集。以下包括已手動或以編程方式存檔的容器（即，在捕獲最後一個容器事件里程碑後 30 天）。',
    totalActive: '總活躍',
    journeyTBC: '旅程待定',
    journeyTBCTooltip: '這些集裝箱要么在旅途中太早，要么沒有可用的海洋跟踪數據；或承運人尚未確認旅程的最後航程',
    arrivedAtPOD: '到達 POD',
    arrivedAtPODTooltip: '這些集裝箱已成功抵達 POD',
    journeyInProgress: '海洋之旅進行中',
    journeyInProgressTooltip: '這些集裝箱目前正在運送到 POD',
    withErrors: '有錯誤',
    carrierDataMissing: '運營商數據丟失',
    carrierDataMissingTooltip: '運營商未返回任何數據。這些貨物可能太早或已經過期。下一步：Portcast 將檢查新的或更新的數據。請在 24 小時後再次嘗試檢查。',
    invalidInput: '輸入無效',
    invalidInputTooltip: '那些可能是錯別字。以下集裝箱似乎不屬於提供的提單/預訂編號。下一步：請仔細檢查是否使用了正確的集裝箱編號、提單/預訂編號。',
    unknownError: '未知錯誤',
    unknownErrorTooltip: '處理這些容器時發生內部錯誤。接下來是什麼：我們正在調查這些問題。請在 12 小時後再次查看。',
    commonReasonsForDelay: '延誤的常見原因',
    portStay: '港口住宿',
    portStayTooltip: '當船舶/集裝箱在到達港口之前在港口區域等待了額外的時間時，就會顯示此事件',
    anchorageStay: '安克雷奇住宿',
    anchorageStayTooltip: '每當船舶/集裝箱在抵達港口之前在錨地區域等待額外時間時，就會顯示此事件',
    nonOptimalRoute: '非最優路線',
    nonOptimalRouteTooltip: '當船隻不再遵循歷史最佳路線時，會顯示此事件',
  },
  'infiniteLoading': {
    noMore: '未找到更多數據',
    noResults: '找不到更多記錄',
    spinner: '正在加載...',
  },
  'loginPage': {
    confirmNewPassword: '確認新密碼',
    confirmNewPasswordPlaceholder: '重新輸入新的密碼',
    emailInvalidError: '不合規電郵',
    emailPlaceholder: '輸入註冊郵箱',
    workEmailPlaceholder: '輸入您的工作電子郵件',
    workEmailValidError: '請使用您的工作電子郵件地址進行註冊。',
    emailRequiredError: '電子郵件是必需的',
    firstName: '名',
    firstNameEnter: '輸入名字',
    forgotPassword: '忘記密碼',
    lastName: '姓',
    lastNameEnter: '輸入姓氏',
    login: '登錄',
    newPassword: '新密碼',
    newPasswordEnter: '輸入新密碼',
    passwordDoNotMatch: '密碼不匹配',
    passwordInvalidError: '無效的密碼',
    passwordPlaceholder: '輸入密碼',
    passwordRequiredError: '密碼是必需的',
    passwordResetSuccess: '密碼重置成功！請嘗試登錄',
    resetPassword: '重設密碼',
    sendResetCode: '發送重置代碼',
    title: '登錄',
    verificationCode: '驗證碼',
    verificationCodeEnter: '輸入驗證碼',
    verificationCodeRequired: '需要驗證碼',
    welcome: '歡迎來到',
    signUp: '報名',
    signUpTitle: '創建您的 Portcast 帳戶',
    signUpDescription: '輸入您的企業電子郵件。您的帳戶憑據和密碼將發送到此電子郵件。',
    registeredSuccessfully: '註冊成功',
    signUpMessage: '帳戶創建正在進行中！您將在 24 小時內收到我們的消息。同時，請觀看我們的產品視頻，了解我們提供的產品。',
  },
  'portLookupPage': {
    code: '代碼',
    country: '國家',
    countryCode: '國家代碼',
    latitude: '緯度',
    longitude: '經度',
    ports: '端口',
    search: '搜索端口名稱或端口代碼',
  },
  'profilePage': {
    masterOrg: '主組織',
    noOfUsers: '用戶數',
    orgApiKey: '組織 API 密鑰',
    orgIdInfo: ' 在電子郵件上傳模板中包含組織 ID。下載下面的示例模板。',
    organisationApiKey: '組織 API 密鑰',
    organizationId: '組織 ID',
    organizationName: '機構名稱',
    organizations: '組織',
    quotaUtilization: '配額利用',
    searchOrganizations: '搜索組織',
    title: '用戶資料',
    userApiKey: '用戶 API 密鑰',
    userDetails: '用戶詳情',
    userID: '用戶身份',
    viewDetails: '查看詳情',
    autoArchive: '自動存檔',
    addCustomField: '新增自訂字段',
    create: '創造',
    fieldName: '欄位名稱',
    fieldType: '字段類型',
    allowMultipleValues: '允許多個值',
    subFields: '子字段',
    newCustomField: '新的自訂字段',
  },
  'singleContainerPage': {
    billOfLadingNo: '提單號',
    copyUrl: '複製網址',
    destination: '目的地',
    detailedEvents: '詳細事件',
    goBack: '回去',
    historical: '歷史的',
    incidents: '事故',
    lastUpdated: '最後更新 (UTC)',
    location: '地點',
    mapView: '地圖視圖',
    modeOfTransport: '交通方式',
    ooops: '哎呀！',
    predicted: '預料到的',
    predictedActual: '預測 */實際',
    resetZoom: '重置縮放',
    somethingWentWrong: '出問題了',
    source: '資源',
    conversations: '對話',
    noMessageFound: '找不到此容器的對話',
    messagePlaceholder: '在此處添加您的評論...使用@ 標記人員。將向他們發送一封電子郵件',
    noMessageFoundError: '提取對話時出錯。請再試一次。',
    fetchingMessages: '正在獲取對話...',
    tryAgainLater: '請稍後再試',
  },
  'vesselsLookupPage': {
    breadthExtreme: '廣度極限',
    callSign: '呼號',
    deadWeight: '自重',
    draught: '草稿',
    engineBuilder: '引擎製造商',
    enginePower: '發動機功率',
    engineStroke: '發動機衝程',
    grossTonnage: '總噸位',
    search: '搜索船隻',
    speed: '速度',
    type: '類型',
    vessels: '船隻',
  },
  'navBar': {
    'control_tower_dashboard': '控制塔',
    'co2_report_dashboard': '二氧化碳報告',
    'port_congestion': ' 港口擁堵',
    'historicalTransit': '運輸時間',
    'dashboard': '控制儀表板',
    'exception_dashboard': '例外情況',
    'historical_transit_time': '歷史運輸時間',
    'accuracy_dashboard': '準確性',
    'latency_accuracy_dashboard': '覆蓋範圍和延遲',
    'vessel-tracking': '船隻追踪',
    'report-dashboard': '報告生成器',
    'report-builder': '報告生成器',
    'login': '登錄',
    'profile': '個人資料頁面：{首字母}',
    'container-tracking': '集裝箱追踪',
    'container-tracking-id': '{containerId}',
    'container-tracking-id-email': '{containerId} - 單個容器跟踪',
    'container-tracking-master-report': '主報告',
    'container-tracking-api': 'API 響應',
    'container-tracking-cached': '緩存書籤',
    'container-tracking-typesense': '搜索索引對象（Typesense）',
    'upload-logs-container': '容器日誌',
    'upload-logs-vessel': '船舶日誌',
    'sailing-schedule': '航行時間表',
    'metadata_ports': '端口元數據',
    'metadata_carriers': '運營商元數據',
    'metadata_vessels': '船舶元數據',
  },
  'onBoarding': {
    title: '入門',
  },
  'Arrived at POD': '到達 POD',
  'Arrived at T/S': '到達 T/S',
  'Departed from POL': '離開POL',
  'Departed from T/S': '從 T/S 出發',
  'Discharged at POD': '在 POD 出院',
  'Discharged at T/S': '在 T/S 處卸貨',
  'Empty Return': '空返回',
  'Gate In Full': '滿門',
  'Gate Out Empty': '門出空',
  'Gate Out Full': '門輸出滿',
  'Loaded at POL': '裝在 POL',
  'Loaded at T/S': '在 T/S 加載',
  'v2': {
    sideNav: {
      addContainers: '添加容器',
      addContainer: '添加容器',
      addContainersSubtitle: '還剩{count}次',
      creditMinimumWarningModalText: '當前的信用額度已用完。您無法再上傳任何貨櫃。如需提高額度，請與我們聯繫。',
      creditZeroErrorModalText: 'Your current credit limit has been exhausted. You can\'t upload any more containers. To request an increase, please get in touch with us.',
      dashboard: '概述',
      tracking: '追踪',
      vesselTracking: '軌道船',
      containerTracking: '跟踪集裝箱',
      reports: '報告',
      portcast_indices: '港播指數',
      portCongestion: '港口擁塞',
      developerTools: '開發工具',
      containerLogs: '容器日誌',
      vesselLogs: '船舶日誌',
      carrierLookup: '運營商查詢',
      portLookup: '端口查詢',
      vesselLookup: '船隻查詢',
      helpCenter: '軌道船',
      knowledgeBase: '知識庫',
      reportAnIssue: '報告問題',
      changeLog: '更改日誌',
      apiDocs: 'API文檔',
      profile: '我的簡歷',
      logout: '登出',
    },
    singleContainerPage: {
      plannedArrival: '最初預定',
      originalETA: '原始預計到達時間',
      latestETA: '最新預定',
      predictedETA: '預計預計到達時間',
      downloadReport: '下載報告',
      download: '下載',
      shareURL: '分享網址',
      share: '分享',
      shareURLToolTip: '點擊此處複製網址',
      shareURLCopyMessage: 'URL 已復製到剪貼板',
      seeRelatedShipments: '查看相關貨件',
      seeAllShipmentsOnVessel: '查看所有船上貨件',
      noRelatedShipments: '在該提單號下未發現其他集裝箱',
      viewContainerDetails: '查看詳細事件',
      blNo: '提單號',
      newUI: '新用戶界面',
      oldUI: '舊用戶界面',
      tabs: {
        detailedEvents: {
          title: '詳細事件',
        },
        productDetails: {
          title: '產品詳情',
        },
        terminalDetails: {
          title: '航廈詳情',
        },
        conversations: {
          title: '對話',
        },
      },
      arrivalAtPOD: '到達 POD',
      carrierArrival: '承運人到達',
      blNumber: '提單號',
      carrierLatestVessel: '承運人和最新船隻',
    },
    noLoginBanner: {
      title: '加入Portcast獲得',
      point1: '所有承運人集裝箱跟踪數據集中在一個地方',
      point2: '您的團隊不再需要手動跟踪',
      point3: '自動更新您的電子郵件和手機',
      requestAccess: '申請進入',
    },
    bulkUpload: {
      containerBulkUpload: '方法一：批量新增容器',
      containerManuallyUpload: '方法二：手動新增容器',
      bulkUploadText: '將您的文件拖放到此處或點擊此處新增文件',
      uploadSupportFile: '我們支援 .CSV、.XLS 和 .XlSX',
      downloadSampleUploadFile: '下載範例上傳文件',
      addManuallyContainers: '按一下此處手動新增容器',
      addingManuallyTakeMoreTime: '手動添加它們通常需要更多時間',
      mapColumns: '將上傳文件中的列對應到以下字段',
      addContainersManually: '手動新增容器',
    },
  },
  'welcomeDialog': {
    title: '歡迎上船！！',
    description: '要開始跟踪容器，第一步是添加它們。要開始上傳過程，只需單擊“添加容器”按鈕即可。',
    processingTitle: '正在處理上傳...',
    processingDescription: '這可能需要幾分鐘。容器上傳成功後，我們會通知您。',
  },
  'co2EmissionLockDialog': {
    title: 'CO2e 排放鎖定',
    description: '您的帳戶目前已鎖定二氧化碳排放量。請聯絡我們解鎖排放數據。',
  },
  'predictionsLockDialog': {
    title: '預測已鎖定',
    description: '您想解鎖容器可見性的預測嗎？預測更加準確，每天都會更新。請聯絡我們來解鎖預測。',
  },
}
